$(document).ready(function() {

    $('a.callback').prop('disabled', false);

    if ($('table:not(.table-custom)').length > 0)
    {
        $('table:not(.table-custom)').attr('width', '');
        $('table').addClass('table-custom');
        $('table').addClass('table-fixed');
        $('table').addClass('condensed-table ');
    }

    $('body').on('click', '.js-callback:not(form)', function (e) {
        e.preventDefault();
        var instance = $(this);

        if (instance.prop('disabled'))
            return false;

        instance.prop('disabled', true);
        instance.addClass('idle');
        var urlCallback = !!instance.data('url') ? instance.data('url') : '/ajax-callback';

        $.ajax({
            url: urlCallback,
            type: 'POST',
            data: instance.data(),
            success: function (json) {
                instance.prop('disabled', false);

                instance.removeClass('idle');

                if (json.result != 'ok') {
                    if (json.message)
                        show_error(json.message);

                    return;
                }

                if (json.action)
                    eval(json.action);

                if (json.message)
                    show_ok(json.title, json.message, json.redirect);
                else if (json.redirect)
                    location.href = json.redirect;
                else if (json.redirect == "")
                    location.reload();
            }
        });

        return false;
    });

    $('body').on('click', 'a.js-callback-yes-no', function (e) {
        e.preventDefault();
        var instance = $(this);
        var message = !!instance.data('message') ? instance.data('message') : 'Вы действительно хотите удалить?';

        var urlCallback = !!instance.closest('form').data('url') ? instance.closest('form').data('url') : '/ajax-callback';

        swal({
            title: 'Вы уверены?',
            text: message,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Отмена',
            confirmButtonText: 'Да'
        }).then(function () {

            if (instance.prop('disabled'))
                return false;

            var button_title = instance.html();
            instance.prop('disabled', true);
            instance.html('Отправка...');
            instance.addClass('idle');

            $.ajax({
                url: urlCallback,
                type: 'POST',
                data: instance.data(),
                success: function (json) {
                    instance.prop('disabled', false);
                    instance.html(button_title);
                    instance.removeClass('idle');

                    if (json.result != 'ok') {
                        if (json.message)
                            show_error(json.message);

                        return;
                    }

                    if (json.action)
                        eval(json.action);

                    if (json.message)
                        show_ok(json.title, json.message, json.redirect);
                    else if (json.redirect)
                        location.href = json.redirect;
                    else if (json.redirect == "")
                        location.reload();
                }
            });
        });

        return false;
    });

    $('form.js-callback [type=submit]').prop('disabled', false);

    $('body').on('submit', 'form.js-callback', function (e) {
    /*$('form.js-callback').submit(function (e) {*/
        e.preventDefault();

        var instance = $(this);

        try {
            if (typeof(tinyMCE) != 'undefined')
                tinyMCE.triggerSave();

        } catch (e) {
            console.log(e);
        }


        if (instance.find("button[type=submit]:focus").hasClass('js-submit-back'))
            instance.find('input[name=js_back]').val('1');
        else
            instance.find('input[name=js_back]').val('0');


        instance.find('.error').removeClass('error');
        var button_title = instance.find('button[type=submit]').text();

        instance.find('button[type=submit]').prop('disabled', true);
        instance.find('button[type=submit]').addClass('idle');

        var urlCallback = !!instance.closest('form').data('url') ? instance.closest('form').data('url') : '/ajax-callback';
        var options = {
            type: 'POST',
            url: urlCallback,
            success: function (json) {

                instance.find('button[type=submit]').prop('disabled', false);
                instance.find('button[type=submit]').removeClass('idle');

                if (json.result != 'ok') {
                    if (json.message)
                        show_error(json.message);

                    if (typeof(json.fields) != 'undefined') {
                        json.fields.every(function (el, i) {
                            instance.find('[name=' + el + ']').addClass('error');
                            instance.find('.wrap-error-' + el).addClass('error');
                            return true;
                        });
                    }

                    return;
                }


                if (json.action)
                    eval(json.action);

                if (json.message) {
                    show_ok(json.title, json.message, json.redirect, json.button)
                }
                else if (json.redirect)
                    location.href = json.redirect;
                else if (json.redirect == "")
                    location.reload();

            },

            error: function () {
                instance.find('button[type=submit]').prop('disabled', false);
                instance.find('button[type=submit]').text(button_title);
                instance.find('button[type=submit]').removeClass('idle');
                show_error('Ошибка отправки данных.<br>Повторите отправку чуть позже');
            }
        };

        $(this).ajaxSubmit(options);
    });


});


function show_error(message) {
    if (!message)
        return;

    $.toast({
        text: message,
        heading: 'Ошибка',
        showHideTransition: 'fade',
        allowToastClose: true,
        hideAfter: 7000,
        stack: 5,
        position: 'bottom-right',

        bgColor: '#E31D24',
        textColor: '#ffffff',
        textAlign: 'left',
        beforeShow: function () {
        },
        afterShown: function () {
        },
        beforeHide: function () {
        },
        afterHidden: function () {
        }
    });
}


function show_ok(title, message, redirect, action) {

    var closeOnConfirm = (typeof(redirect) == 'undefined');
    title = (typeof(title) == 'undefined') ? '' : title;

    swal({
        title: title,
        text: message,
        type: "success",
        showCancelButton: false,
        confirmButtonText: "Закрыть",
        allowOutsideClick: false,
        closeOnConfirm: closeOnConfirm }).then(function() {
        if (!redirect || redirect.length == 0)
            location.reload();
        else {
            if (redirect != 'noredirect')
                location.href = redirect;
        }
    });

}